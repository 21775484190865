@import '../../../../../../themes/main.scss';

.navbar-menu-dropdown {
  display: block;
  position: fixed;
  z-index: 100;
  margin: 100px 10px 0 10px;
  top: 0;
  right: 0;
  height: 100%;

  .menu-container {
    display: flex;
    flex-direction: column;
    padding: 0;
    background-color: #ffffff;
    font-family: Roboto;
    font-style: normal;
    font-size: 1rem;
    line-height: 1rem;
    letter-spacing: 0.1px;
    box-sizing: border-box;
    border-top: 1px solid $primary-color;
    border-bottom: 1px solid $primary-color;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 0px 5px 5px;
  }
}
